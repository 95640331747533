<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M16.355 26.5067L15 31.25L13.645 26.5067C13.2948 25.2814 12.6382 24.1656 11.7371 23.2646C10.836 22.3635 9.72023 21.7069 8.495 21.3567L3.75 20L8.49333 18.645C9.71857 18.2948 10.8344 17.6382 11.7354 16.7371C12.6365 15.836 13.2931 14.7202 13.6433 13.495L15 8.75L16.355 13.4933C16.7052 14.7186 17.3618 15.8344 18.2629 16.7354C19.164 17.6365 20.2798 18.2931 21.505 18.6433L26.25 20L21.5067 21.355C20.2814 21.7052 19.1656 22.3618 18.2646 23.2629C17.3635 24.164 16.7069 25.2798 16.3567 26.505L16.355 26.5067ZM30.4317 14.525L30 16.25L29.5683 14.525C29.3212 13.536 28.81 12.6327 28.0893 11.9117C27.3687 11.1907 26.4656 10.6792 25.4767 10.4317L23.75 10L25.4767 9.56833C26.4656 9.32085 27.3687 8.80927 28.0893 8.08828C28.81 7.3673 29.3212 6.46402 29.5683 5.475L30 3.75L30.4317 5.475C30.6789 6.46423 31.1903 7.36765 31.9114 8.08865C32.6324 8.80965 33.5358 9.32111 34.525 9.56833L36.25 10L34.525 10.4317C33.5358 10.6789 32.6324 11.1904 31.9114 11.9114C31.1903 12.6324 30.6789 13.5358 30.4317 14.525ZM28.1567 34.2783L27.5 36.25L26.8433 34.2783C26.6592 33.726 26.3491 33.2242 25.9374 32.8126C25.5258 32.4009 25.0239 32.0908 24.4717 31.9067L22.5 31.25L24.4717 30.5933C25.0239 30.4092 25.5258 30.0991 25.9374 29.6874C26.3491 29.2758 26.6592 28.7739 26.8433 28.2217L27.5 26.25L28.1567 28.2217C28.3408 28.7739 28.6509 29.2758 29.0626 29.6874C29.4742 30.0991 29.976 30.4092 30.5283 30.5933L32.5 31.25L30.5283 31.9067C29.976 32.0908 29.4742 32.4009 29.0626 32.8126C28.6509 33.2242 28.3408 33.726 28.1567 34.2783Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M3.75 31.25C12.6427 31.2428 21.4967 32.4203 30.0783 34.7517C31.29 35.0817 32.5 34.1817 32.5 32.925V31.25M6.25 7.5V8.75C6.25 9.08152 6.1183 9.39946 5.88388 9.63388C5.64946 9.8683 5.33152 10 5 10H3.75M3.75 10V9.375C3.75 8.34 4.59 7.5 5.625 7.5H33.75M3.75 10V25M33.75 7.5V8.75C33.75 9.44 34.31 10 35 10H36.25M33.75 7.5H34.375C35.41 7.5 36.25 8.34 36.25 9.375V25.625C36.25 26.66 35.41 27.5 34.375 27.5H33.75M3.75 25V25.625C3.75 26.1223 3.94754 26.5992 4.29917 26.9508C4.65081 27.3025 5.12772 27.5 5.625 27.5H6.25M3.75 25H5C5.33152 25 5.64946 25.1317 5.88388 25.3661C6.1183 25.6005 6.25 25.9185 6.25 26.25V27.5M33.75 27.5V26.25C33.75 25.9185 33.8817 25.6005 34.1161 25.3661C34.3505 25.1317 34.6685 25 35 25H36.25M33.75 27.5H6.25M25 17.5C25 18.8261 24.4732 20.0979 23.5355 21.0355C22.5979 21.9732 21.3261 22.5 20 22.5C18.6739 22.5 17.4021 21.9732 16.4645 21.0355C15.5268 20.0979 15 18.8261 15 17.5C15 16.1739 15.5268 14.9021 16.4645 13.9645C17.4021 13.0268 18.6739 12.5 20 12.5C21.3261 12.5 22.5979 13.0268 23.5355 13.9645C24.4732 14.9021 25 16.1739 25 17.5ZM30 17.5H30.0133V17.5133H30V17.5ZM10 17.5H10.0133V17.5133H10V17.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts">
const cards = computed(() => [
  {
    title: 'Garantia de qualidade',
    text: 'Laboratórios parceiros cuidadosamente selecionados para garantir os mais altos padrões de qualidade em seus exames.',
    icon: resolveComponent('IconSpark'),
  },
  {
    title: 'Liberdade de escolha',
    text: 'Transparência total no custo dos seus exames, para que você possa tomar decisões assertivas sobre a sua saúde, sem surpresas.',
    icon: resolveComponent('IconControls'),
  },
  {
    title: 'Suporte técnico qualificado',
    text: 'Time técnico disponível a um clique de distância oferecendo auxílio e tirando suas dúvidas',
    icon: resolveComponent('IconMessage'),
  },
  {
    title: 'Exames com preço justo',
    text: 'Ampla rede de laboratórios parceiros, garantindo acesso a preços competitivos. Buscar os melhores preços nunca foi tão fácil!',
    icon: resolveComponent('IconMoney'),
  },
])
</script>

<template>
  <section class="bg-secondary-300 py-14 md:py-24">
    <div class="container mx-auto px-5 lg:px-0 grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <h3 class="text-center md:text-left text-white text-2xl font-sora font-semibold mb-2">
          Por que escolher<br /> {{ isTrevo() ? 'Saúde Trevo' : 'Nosso laboratório' }}
        </h3>
        <p class="text-center md:text-left text-white text-base">
          Acreditamos que uma boa saúde começa com boas escolhas. Nosso compromisso é unir inovação tecnológica e um toque humano.
        </p>
      </div>

      <div class="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
        <div
          v-for="(card, i) in cards"
          :key="i"
          class="flip-card relative md:h-60 flex-wrap"
        >
          <div class="inner bg-base-50 rounded-lg border border-neutral-50">
            <div class="front p-6 md:h-full flex flex-row md:flex-col justify-start md:justify-between gap-4">
              <component :is="card.icon" class="text-secondary-500" />
              <h4 class="text-neutral-300 text-2xl font-sora font-semibold">
                {{ card.title }}
              </h4>
            </div>

            <p
              class="back bg-base-50 p-6 w-full mt-4 md:m-0 md:p-4 md:rounded-lg flex items-center text-neutral-200 text-base"
            >
              {{ card.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (min-width: 768px) {
  .inner {
    position: relative;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    height: 100%;
    width: 100%;
  }

  .flip-card:hover .inner {
    transform: rotateY(180deg);
  }

  .front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .back {
    transform: rotateY(180deg);
  }
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M17.5 10H33.75M17.5 10C17.5 10.663 17.2366 11.2989 16.7678 11.7678C16.2989 12.2366 15.663 12.5 15 12.5C14.337 12.5 13.7011 12.2366 13.2322 11.7678C12.7634 11.2989 12.5 10.663 12.5 10M17.5 10C17.5 9.33696 17.2366 8.70107 16.7678 8.23223C16.2989 7.76339 15.663 7.5 15 7.5C14.337 7.5 13.7011 7.76339 13.2322 8.23223C12.7634 8.70107 12.5 9.33696 12.5 10M12.5 10H6.25M17.5 30H33.75M17.5 30C17.5 30.663 17.2366 31.2989 16.7678 31.7678C16.2989 32.2366 15.663 32.5 15 32.5C14.337 32.5 13.7011 32.2366 13.2322 31.7678C12.7634 31.2989 12.5 30.663 12.5 30M17.5 30C17.5 29.337 17.2366 28.7011 16.7678 28.2322C16.2989 27.7634 15.663 27.5 15 27.5C14.337 27.5 13.7011 27.7634 13.2322 28.2322C12.7634 28.7011 12.5 29.337 12.5 30M12.5 30H6.25M27.5 20H33.75M27.5 20C27.5 20.663 27.2366 21.2989 26.7678 21.7678C26.2989 22.2366 25.663 22.5 25 22.5C24.337 22.5 23.7011 22.2366 23.2322 21.7678C22.7634 21.2989 22.5 20.663 22.5 20M27.5 20C27.5 19.337 27.2366 18.7011 26.7678 18.2322C26.2989 17.7634 25.663 17.5 25 17.5C24.337 17.5 23.7011 17.7634 23.2322 18.2322C22.7634 18.7011 22.5 19.337 22.5 20M22.5 20H6.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

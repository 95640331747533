<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M33.75 14.185C35.2233 14.6583 36.25 16.065 36.25 17.68V24.8233C36.25 26.7167 34.8383 28.3233 32.95 28.4783C32.3833 28.5233 31.8167 28.565 31.25 28.5983V33.75L26.25 28.75C23.9933 28.75 21.76 28.6583 19.55 28.4783C19.0689 28.4394 18.6009 28.3022 18.175 28.075M33.75 14.185C33.4925 14.1021 33.2263 14.049 32.9567 14.0267C28.4932 13.6561 24.0068 13.6561 19.5433 14.0267C17.6583 14.1833 16.25 15.7883 16.25 17.68V24.8233C16.25 26.2183 17.0167 27.4567 18.175 28.075M33.75 14.185V11.0617C33.75 8.36 31.83 6.01834 29.15 5.67C25.7014 5.22299 22.2275 4.99919 18.75 5C15.225 5 11.7533 5.22834 8.35 5.67C5.67 6.01834 3.75 8.36 3.75 11.0617V21.4383C3.75 24.14 5.67 26.4817 8.35 26.83C9.31167 26.955 10.2783 27.0633 11.25 27.1533V35L18.175 28.075"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
